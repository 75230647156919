import cx from 'classnames';
import ReactDOM from 'react-dom';
import Close from 'public/icons/close.svg';

import styles from './snackbar.module.css';

const Snackbar = ({ children, open, onClose }) => {
  if (!open) {
    return null;
  }

  return (
    ReactDOM.createPortal(
      <div className={cx(styles.snackbar, 'fixed w-full pl-6 pr-8 py-4 text-center')}>
        {children}
        <Close onClick={onClose} className={cx(styles.close, 'absolute w-6 h-6')} />
      </div>,
      document.body,
    )
  );
};

export default Snackbar;
