import getConfig from 'next/config';

import { setLocal, getLocal, clearLocal } from 'utils/storage';

const { publicRuntimeConfig } = getConfig();

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

const GRAPHQL_PATH = () => publicRuntimeConfig?.GRAPHQL_HOST;
const GOOGLE_CLIENT_ID = () => publicRuntimeConfig?.GOOGLE_CLIENT_ID;
const SSE_PATH = () => GRAPHQL_PATH()?.replace('/graphql', '/sse');

const setToken = (key, token, expires) => {
  setLocal(key, JSON.stringify({
    value: token,
    expires,
  }));
};
const getToken = (key) => {
  const data = getLocal(key);
  if (!data) {
    return null;
  }

  const item = JSON.parse(data);
  if (Date.now() > item.expires * 1000) {
    clearLocal(key);
    return null;
  }

  return item.value;
};
const clearToken = (key) => clearLocal(key);

export {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,

  GRAPHQL_PATH,
  GOOGLE_CLIENT_ID,
  SSE_PATH,

  setToken,
  getToken,
  clearToken,
};
