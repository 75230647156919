import Head from 'next/head';
import Script from 'next/script';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from 'utils/api';

import { withMedia } from 'components/mediaQuery';
import SadFace from 'public/icons/sad_face.svg';
import Header from './header';
import Footer from './footer';
import Banner from './banner';
import Menu from './menu';

import styles from './layout.module.css';

const browsers = [
  {
    name: 'chrome',
    url: 'https://www.google.co.uk/chrome',
  },
  {
    name: 'firefox',
    url: 'https://www.mozilla.org/en-GB/firefox/new/',
  },
  {
    name: 'safari',
    url: 'https://support.apple.com/en_GB/downloads/safari',
  },
  {
    name: 'opera',
    url: 'https://www.opera.com/uk/download',
  },
];

const Layout = ({ children }) => {
  const [notSupported, setNotSupported] = useState(false);

  useEffect(() => {
    if (window && !window.IntersectionObserver) {
      setNotSupported(true);
    }
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover" />
        <title>Poolup | Free community betting service</title>
        <meta name="description" content="Easy registration, no payments, no verification. Simple and clear rules. A few clicks and pool is ready" />
        <meta name="robots" content="max-image-preview:large" />
        <link rel="preconnect" href="https://www.googletagmanager.com" crossorigin="" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/og-website-image.png" />
        <meta property="og:title" content="Free community betting service | Poolup ↑" />
        <meta property="og:site_name" content="Poolup ↑" />
        <meta property="og:url" content="https://poolup.bet" />
        <meta property="og:description" content="Easy registration, no payments, no verification. Simple and clear rules. A few clicks and pool is ready." />
        <link rel="icon" href="/favicon.ico" />
        <meta name="apple-mobile-web-app-title" content="Poolup" />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Head>
      <Script async src="https://www.googletagmanager.com/gtag/js?id=AW-11463580162" />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-11463580162');
        `}
      </Script>
      {notSupported ? (
        <section className={styles.notSup}>
          <Header />
          <SadFace className={styles.sadFace} />
          <h1 className="text-center text-2xl">Browser is not supported</h1>
          <p className={cx(styles.desc, 'text-center mt-4 max-w-2xl px-4 mb-8')}>
            Looks like you are using an old browser,
            which is not supported by this website. Please, use Google Chrome,
            Mozilla Firefox, Safari, Opera for the best user experience.
          </p>
          <div className={cx(styles.browsers, 'mb-8')}>
            {
              browsers.map(({ name, url }) => (
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  key={name}
                  className={cx(styles.browser, styles[name])}
                >
                  {name}
                </a>
              ))
            }
          </div>
          <div className={styles.space} />
          <Footer />
        </section>
      ) : (
        <>
          <div className={styles.wrapper}>
            <Banner />
            <Header />
            <Menu />
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID()}>
              <main className={styles.container}>
                {children}
              </main>
            </GoogleOAuthProvider>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default withMedia(Layout);
