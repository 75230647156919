import Link from 'next/link';
import cx from 'classnames';
import { withRouter } from 'next/router';
import { menuConfig, playConfig } from 'utils/menu';

import styles from './menu.module.css';

const menuItems = playConfig.concat(menuConfig);

const Menu = ({ router }) => (
  <div className={cx(styles.container, 'flex')}>
    <nav className="flex items-center">
      {
          menuItems.map(({ name, link, short }) => {
            const activeLink = Array.isArray(link) ? link : [link];

            return (
              <Link key={activeLink[0]} href={activeLink[0]}>
                <a className={cx(styles.item, 'flex-center text-sm py-2 px-4 hover:underline', {
                  [styles.active]: activeLink.includes(router.route),
                })}
                >
                  <span className={styles.text}>{name}</span>
                  <span className={styles.short}>{short}</span>
                </a>
              </Link>
            );
          })
        }
    </nav>
  </div>
);

export default withRouter(Menu);
