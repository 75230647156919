import cx from 'classnames';
import Link from 'next/link';
import Head from 'next/head';

import Screw from 'public/screw.svg';

import styles from './404.module.css';

const Custom404 = ({
  title = 'Page not found',
  nums = [4, 0, 4],
  text = 'Left, Right. Opps Lost',
  buttonText = 'Back to Home',
  buttonClick = null,
}) => (
  <>
    <Head>
      <title>{title}</title>
    </Head>
    <div className={cx(styles.topLine, 'absolute')} />
    {
      nums.map((it, i) => (
        <div
          key={`${it}-${i}`}
          className={cx(styles.block, {
            [styles.center]: i === 1,
            [styles.right]: i === 2,
          }, 'absolute')}
        >
          <div className={cx(styles.line, 'absolute')}>
            <Screw className={cx(styles.screw, 'absolute')} />
          </div>
          <div className={styles.letter}>{it}</div>
        </div>
      ))
    }
    <div className={cx(styles.info, 'text-center')}>
      <h1 className="text-3xl">{text}</h1>
      <Link href="/">
        <button
          type="submit"
          className="mt-6 bg-primary-main hover:bg-primary-dark text-white font-bold py-2 px-8 rounded"
          onClick={buttonClick}
        >
          {buttonText}
        </button>
      </Link>
    </div>
  </>
);

export default Custom404;
