import Calender from 'public/menu/calender.svg';
import Pools from 'public/menu/pools.svg';
import Bets from 'public/menu/bet-panel.svg';

export const menuConfig = [
  {
    name: 'Events calendar', short: 'Calendar', link: '/bets', icon: Calender,
  },
  {
    name: 'Active Pools', short: 'Pools', link: ['/pools'], icon: Pools,
  },
];

export const logConfig = { name: 'Log in', link: '/signin' };
export const balanceConfig = { name: 'Balance', link: '/balance' };
export const userConfig = { name: 'User', link: '/account' };
export const basketConfig = { name: 'Basket', link: '/basket' };
export const analyticsConfig = { link: '/analytics' };
export const ratingConfig = { link: '/rating' };

export const playConfig = [
  {
    name: 'Pools Panel', short: 'Pools Panel', link: ['/bets/create', '/bets/create/[bets]'], icon: Bets,
  },
  {
    name: 'Create Private', short: 'Create Private', link: '/private', hasBorder: true,
  },
];
