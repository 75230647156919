import React from 'react';
import { withRouter } from 'next/router';

import Custom404 from 'pages/404';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.router.route !== this.props.router.route) {
      this.setState({
        hasError: false,
      });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Custom404
          title="Something went wrong"
          nums={[5, 0, 0]}
          text="Something went wrong"
          buttonText="Refresh page"
          buttonClick={(ev) => {
            ev?.preventDefault?.();
            ev?.stopPropagetion?.();
            window.location.reload();
          }}
        />
      );
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
