import { useEffect, useState } from 'react';
import ReconnectingEventSource from 'reconnecting-eventsource';
import { SSE_PATH, ACCESS_TOKEN_KEY, getToken } from 'utils/api';

const useSseUpdate = (user) => {
  const [openSse, setOpenSse] = useState();
  const [updatedSse, setUpdated] = useState();
  const [sourceObj, setSourceObj] = useState();
  const { nickname } = user || {};
  const getEvtSourceConOrOpen = () => openSse || sourceObj?.readyState === 0 || sourceObj?.readyState === 1;

  useEffect(async () => {
    let evtSource;
    const token = getToken(ACCESS_TOKEN_KEY);
    if (nickname && token) {
      evtSource = new ReconnectingEventSource(`${SSE_PATH()}?token=${token}`, { withCredentials: true, max_retry_time: 5000 });
      setSourceObj(evtSource);

      evtSource.onopen = () => {
        setOpenSse(true);
      };

      evtSource.onmessage = (event) => {
        setOpenSse(true);
        setUpdated(event);
      };

      evtSource.onerror = (err) => {
        console.error(err, 'sse error');
        setOpenSse(false);
      };
    }

    return () => evtSource?.close?.();
  }, [nickname]);

  return {
    getEvtSourceConOrOpen,
    updatedSse,
  };
};

export default useSseUpdate;
