import { gql } from '@apollo/client';

const GET_USER_DATA = gql`
  query { 
    user { 
      email
      nickname
      protected
      accountInfo { 
        balance { units }
        roi
        rating
      } 
    }
  }
`;

const GET_USER_NOTIFICATIONS = gql`
  query Notifications($includeAll: Boolean) {
    notifications(includeAll: $includeAll) {
      id
      code
      message
      details
    }
  }
`;

const GET_USER_PROMOTIONS = gql`
  query Promotions {
    promotions {
      code
      activeFrom
      reward {
        units
      }
    }
  }
`;

const CLAIM_PROMOTION = gql`
  mutation ClaimPromotion($code: String!) {
    claimPromotion(code: $code)
  }
`;

const MARK_VIED_NOTIFICATIONS = gql`
  mutation MarkViedNotifications($ids: [ID!]) {
    markViedNotifications(ids: $ids)
  }
`;

const LOGIN_USER = gql`
  mutation Login($email: String!, $token: String) {
    login(basic: {email: $email, token: $token}) {
      newUserToken
      tokens {
        access {
          value
          expires
        }
        refresh {
          value
          expires
        }
      }
    }
  }
`;

const LOGIN_GOOGLE_USER = gql`
  mutation Login($code: String!) {
    login(google: {code: $code}) {
      newUserToken
      tokens {
        access {
          value
          expires
        }
        refresh {
          value
          expires
        }
      }
    }
  }
`;

const LOGIN_REFRESH = gql`
  mutation Refresh($token: String!) {
    refresh(token: $token) {
      access {
        value
        expires
      }
      refresh {
        value
        expires
      }
    }
  }
`;

const CREAT_USER = gql`
  mutation CreateUser($token: String!, $nickname: String!) {
    createUser(token: $token, nickname: $nickname) {
      user {
        nickname
      }
      tokens {
        access {
          value
          expires
        }
        refresh {
          value
          expires
        }
      }
    }
  }
`;

const CHECK_NICKNAME = gql`
  query CheckNickname($nickname: String!) {
    checkNickname(nickname: $nickname) {
      available
      suggestions
    }
  }
`;

const LOGOUT_USER = gql`
  mutation {
    logout
  }
`;

const ENABLE_2FA = gql`
  mutation Enable2FA($token: String) {
    enable2FA(token: $token) {
      success, uri
    }
  }
`;

const GET_TICKET = gql`
  query ticket ($id:  String!) {
    findTickets (filter: {id: {eq: $id} }) { 
      id
      name
      formats
      prices
      events {
        id
        state
        probability
        startTime

        ... on FootballEvent {
          team1 { name, abbr, id }
          team2 { name, abbr, id }
        }
      } 
    }
  }
`;

const GET_COMPETITIONS = gql`
  query competitions ($sport: Int, $year: Int) {
    findCompetitions (filter: {sport: {eq: $sport}, year: {eq: $year} }) { 
      id
      name
    }
  }
`;

const GET_EVENTS = gql`
  query events ($competitionId: [String!], $withProbability: Boolean, $from: Int, $to: Int, $state: [Int!]) {
    findEvents (filter: {competitionId: {in: $competitionId}, withProbability: $withProbability, startTime: {lt: $to, gt: $from}, state: {in: $state} }) { 
      startTime
      probability
      id
      phase

      ... on FootballEvent {
        team1 { name, abbr, id }
        team2 { name, abbr, id }
      }
    }
  }
`;

const FIND_TICKETS = gql`
  query {
      findTickets (filter: {state: {eq: 1} }) {
        id
        name
        rounds
        formats
        prices
        eventIds
        events {
          startTime
          competition {
            id
            name
          }
        }
      }
  }
`;

const CREATE_BET = gql`
  mutation createBet($ticketId: ID!, $format: Int!, $amount: Int!, $lines: [String!]!, $numOfBets: Int) {
    createBet(ticketId: $ticketId, format: $format, amount: $amount, lines: $lines, numOfBets: $numOfBets)
  }
`;

const CREATE_PRIVATE = gql`
  mutation createPrivatePool($name: String!, $eventIds: [String!]!, $amount: Int!, $lines: [String!]!) {
    createPrivatePool(name: $name, eventIds: $eventIds, amount: $amount, lines: $lines)
  }
`;

const GET_LINES = gql`
  query FindLines($offset: Int, $limit: Int, $name: String, $to: Int, $from: Int) { 
    findLines(pagination: { offset: $offset, limit: $limit }, filter: {name: {like: $name}, startTime: {lt: $to, gt: $from} }) {
      ticket {
        id
        name  
        fixedEventIds
        events {
          id
          state
          startTime
        }
      }

      bet {
          id
          lines
      }

      event {
          id
          state
          startTime
          probability
          score { side1, side2 }

          ... on FootballEvent {
              team1 { id, abbr }
              team2 { id, abbr }
          }
      }
      state
      score
    }
  }
`;

const GET_USER_BET = gql`
  query GetBet($id: String!) {
    findLines(filter: {id: {eq: $id} }) {
      bet {
        id
        lines
      }
      ticket {
        id
        name
        fixedEventIds
        events {
          id,
          probability,
          startTime,

          ... on FootballEvent {
            team1 { name, abbr, id }
            team2 { name, abbr, id }
          }
        }    
      }
    }
  }
`;

const TICKET_NAME_QUERY = gql`
  query findTickets ($ids:  [String!]) {
    findTickets (filter: { id: { in: $ids} }) { id, name }
  }
`;

const GET_POOLS = gql`
  query FindPools($offset: Int, $limit: Int, $name: String, $to: Int, $from: Int) {
    findPools(pagination: { offset: $offset, limit: $limit }, filter: {name: {like: $name}, startTime: {lt: $to, gt: $from} }) {
      ticket {
        name,
        fixedEventIds
        events {
          id
          startTime
          probability
  
          ... on FootballEvent {
            team1 { id, abbr, name } 
            team2 { id, abbr, name }
          }
        }
      }

      bet {
        id
        format
        amount
        lines
      }
      state
      startTime
      owner

      details {
        position
        isWinningPosition
      }
    }
  }
`;

const GET_INSIDE_POOL = gql`
  query pool($id: String!) {
    findPools(filter: { id: { eq: $id} }) {
      ticket {
        name
        rounds
        id
        fixedEventIds
      }
      bet {
        id
        format
        amount
      }
      events {
        id
        state
        startTime 
        probability 
        score { side1, side2 }

        ... on FootballEvent {
          team1 { id, abbr, name } 
          team2 { id, abbr, name }
        }
      }
      details {
        lines {
          nickname
          total
          events {
            eventId
            prediction
            score
            isBackupScore
          }
        }
        accessToken
      }
      owner
      chatId
      state
    }
  }
`;

const GET_INSIDE_POOL_PRIVATE = gql`
  query GetPrivatePool($id: ID!) {
    getPrivatePool(id: $id) {
      ticket {
        name
        rounds,
        formats,
        prices,
        fixedEventIds,
        id
      }
      bet {
        id
        format
        amount
      }
      events {
        id
        state
        startTime 
        probability 
        score { side1, side2 }

        ... on FootballEvent {
          team1 { id, abbr, name } 
          team2 { id, abbr, name }
        }
      }
      details {
        lines {
          nickname
          total
          events { 
            eventId
            prediction
            score
            isBackupScore
          }
        }
      }
      owner
      chatId
      state
    }
  }
`;

const POOL_HISTORY_QUERY = gql`
query FindUserStats($filter: HistoryFilter) {
  findHistory(filter: $filter) { entries {
    activatedAt
    poolName
    amount
    format
    netScore
    place
    won
    numOfLines
    prize
    poolId
    betId
  }
}}
`;

const GET_CHAT_MESSAGES = gql`
  query ChatMessages($chatId: ID!, $lastRevision: Int) {
    findChatMessages(chatId: $chatId, lastRevision: $lastRevision, includeMetadata: true) {
      messages {
        createdAt
        revision
        playerId
        message
      }

      metadata {
        participants {
          playerId
          nickname
        }
      }
    }
  }
`;

const SEND_CHAT_MESSAGE = gql`
  mutation sendChatMessage($chatId: ID!, $message: String!) {
    sendChatMessage(chatId: $chatId, message: $message)
  }
`;

const SEND_FEEDBACK = gql`
  mutation sendFeedback($name: String!, $email: String!, $topic: String!, $subtopic: String!, $message: String!) {
    sendFeedback(name: $name, email: $email, topic: $topic, subtopic: $subtopic, message: $message)
  }
`;

const GET_RATING = gql`
  query GetRating {
    getRating {
      place,
      nickname,
      rating,
      roi,
    }
  }
`;

export {
  GET_USER_DATA,
  GET_USER_NOTIFICATIONS,
  GET_USER_PROMOTIONS,
  CLAIM_PROMOTION,
  MARK_VIED_NOTIFICATIONS,
  LOGIN_USER,
  LOGIN_GOOGLE_USER,
  LOGIN_REFRESH,
  LOGOUT_USER,
  ENABLE_2FA,
  GET_TICKET,
  GET_COMPETITIONS,
  GET_EVENTS,
  FIND_TICKETS,
  CREATE_BET,
  CREATE_PRIVATE,
  GET_LINES,
  GET_POOLS,
  GET_INSIDE_POOL,
  GET_INSIDE_POOL_PRIVATE,
  TICKET_NAME_QUERY,
  POOL_HISTORY_QUERY,
  GET_USER_BET,
  CREAT_USER,
  CHECK_NICKNAME,
  GET_CHAT_MESSAGES,
  SEND_CHAT_MESSAGE,
  SEND_FEEDBACK,
  GET_RATING,
};
