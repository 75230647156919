import { useContext, useEffect, useState } from 'react';
import Link from 'next/link';
import cx from 'classnames';
import { withRouter } from 'next/router';

import {
  playConfig, menuConfig, logConfig, userConfig, ratingConfig, basketConfig, analyticsConfig,
} from 'utils/menu';
import UserContext from 'utils/context';

import { marker } from 'utils/test';
import BalanceLink from './balanceLink';

import styles from './header.module.css';

const Header = ({ router }) => (
  <header className={styles.header}>
    <div className={cx(styles.inner, 'py-6 px-6')}>
      <Link href="/">
        <a className={cx(styles.logo, 'mr-4 hover:underline')}>
          Poolup
        </a>
      </Link>
      <Menu route={router?.route} />
    </div>
  </header>
);

const MenuLinks = ({ list = [], route }) => (
  <>
    {list.map(({
      name, link, icon: Icon, hasBorder,
    }) => {
      const activeLink = Array.isArray(link) ? link : [link];
      const active = activeLink.includes(route);

      return (
        <Link key={name} href={activeLink[0]}>
          <a className={cx('text-white py-2 px-4 underline', styles.menuItem, {
            [styles.menuActive]: active,
            [styles.menuBorder]: hasBorder,
          })}
          >
            { Icon && <Icon /> }
            {name}
          </a>
        </Link>
      );
    })}
  </>
);

const Menu = ({ route }) => {
  const { user, basket } = useContext(UserContext);
  const [isFlash, setFlash] = useState(false);
  useEffect(() => {
    setFlash(true);
    const timeOut = setTimeout(() => setFlash(false), 800);
    return () => clearTimeout(timeOut);
  }, [basket]);

  const accountSection = () => {
    if (!user.accountInfo) {
      return (
        <Link href="/open">
          <a className="mr-2 text-white hover:underline">Open Account</a>
        </Link>
      );
    }

    return (
      <>
        <BalanceLink route={route} />
        <Link href={ratingConfig.link}>
          <a className={cx(styles.smallLink, 'flex text-white underline px-2 py-1 mr-4', {
            [styles.menuActive]: ratingConfig.link === route,
          })}
          >
            <span className={cx(styles.headerLink)}>
              Rating:
              {' '}
              {user.accountInfo.rating?.toFixed?.(2)}
            </span>
          </a>
        </Link>
        <Link href={analyticsConfig.link}>
          <a className={cx(styles.smallLink, 'flex text-white underline px-2 py-1 mr-2', {
            [styles.menuActive]: analyticsConfig.link === route,
          })}
          >
            <span className={cx(styles.headerLink)}>
              ROI:
              {' '}
              {user.accountInfo.roi?.toFixed?.(2)}
              %
            </span>
          </a>
        </Link>
      </>
    );
  };

  if (user) {
    const basketLen = basket.bets.length + basket.private.length;

    return (
      <>
        <nav className={cx(styles.center, styles.start, 'pr-4')}>
          <MenuLinks list={playConfig} route={route} />
        </nav>
        <nav className={cx(styles.center, 'pr-4')}>
          <MenuLinks list={menuConfig} route={route} />
        </nav>
        <div className="flex items-center relative">
          {accountSection()}
          <Link href={userConfig.link}>
            <a
              className={cx(styles.left, styles.user, 'flex-center uppercase text-xl', {
                [styles.selected]: userConfig.link === route,
              })}
            >
              {user.nickname[0]}
            </a>
          </Link>
          { Boolean(basketLen) && (
            <Link href={basketConfig.link}>
              <a className={cx(styles.betslip, { [styles.flash]: isFlash })}>
                {`Prediction Slip (${basketLen})`}
              </a>
            </Link>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <nav className={cx(styles.center, styles.start, 'pr-4 pl-8')}>
        <MenuLinks list={playConfig} route={route} />
      </nav>
      <nav className={cx(styles.center, 'pr-4')}>
        <MenuLinks list={menuConfig} route={route} />
      </nav>
      <div className="flex items-center relative">
        <Link href={logConfig.link}>
          <a
            className={cx(styles.left, styles.login, 'flex-center', {
              [styles.selected]: logConfig.link === route,
            })}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...marker('signin')}
          >
            Log in | Join
          </a>
        </Link>
      </div>
    </>
  );
};

export default withRouter(Header);
