import React from 'react';

const UserContext = React.createContext({
  user: {},
  loading: false,
  updateUser: () => {},
});

UserContext.displayName = 'UserContextyName';

export default UserContext;
