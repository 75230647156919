import { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallDataIfLoggedIn } from 'utils/customHooks';

import { GET_USER_NOTIFICATIONS, MARK_VIED_NOTIFICATIONS } from 'lib/api';

import Text from './text.json';

const ToastNotifications = () => {
  const { addToast } = useToasts();
  const [getUserData, { data = {} }] = useLazyQuery(GET_USER_NOTIFICATIONS, {
    pollInterval: 60000,
  });
  const [markVied] = useMutation(MARK_VIED_NOTIFICATIONS);

  useCallDataIfLoggedIn(getUserData);

  useEffect(() => {
    if (data?.notifications) {
      const filtered = data.notifications.reduce((acc, n) => {
        if (acc[n.code]) {
          acc[n.code].ids.push(n.id);
        } else {
          acc[n.code] = {
            ...n,
            ids: [],
          };
        }

        return acc;
      }, {});

      Object.values(filtered).forEach(({ code, id, ids }) => {
        const info = Text[code];
        addToast(<p>{info.text}</p>, {
          appearance: info.appearance,
          autoDismiss: true,
          onDismiss: () => markVied({ variables: { ids: ids.concat(id) } }),
        });
      });
    }
  }, [data?.notifications]);

  return null;
};

export default ToastNotifications;
