const PL_BANNER = 'pl_banner';
const PL_THEME = 'pl_theme';

const SAVED_COOKIE = 'saved_cookie';

const PLACE_BETS_INFO = 'place_bets_info';
const PLACED_BETS = 'placed_bets';
const BASKET_BETS = 'basket_bets';
const BASKET_PRIVATE = 'basket_private';
const LAST_BET = 'last_bet';

const POOL_PIN = 'pool_pin';

const PROMO_ITEM = 'promo_item';

const ADD_BET = 'add_bet';

export {
  ADD_BET,
  PLACED_BETS,
  BASKET_BETS,
  BASKET_PRIVATE,
  PLACE_BETS_INFO,
  PL_BANNER,
  PL_THEME,
  SAVED_COOKIE,
  LAST_BET,
  POOL_PIN,
  PROMO_ITEM,
};
