import { useEffect, useState } from 'react';
import cx from 'classnames';

import { setToLocal, getRemoveFromLocal } from 'utils/storage';
import { PL_BANNER } from 'utils/localStorageKey';

import Close from 'public/icons/close.svg';

import styles from './banner.module.css';

const Banner = () => {
  const [open, setOpen] = useState(true);

  const closeHandler = () => {
    setOpen(true);
    setToLocal(PL_BANNER, true);
  };

  useEffect(() => {
    setOpen(getRemoveFromLocal(PL_BANNER));
  }, []);

  return (
    <div className={cx(styles.banner, {
      [styles.closed]: true,
    })}
    >
      <div className={cx(styles.inner, 'px-8 pt-4 pb-3')}>
        <Close onClick={closeHandler} className={cx(styles.close, 'w-6 h-6')} />
      </div>
    </div>
  );
};

export default Banner;
