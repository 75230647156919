/* eslint react/jsx-props-no-spreading: 0 */

import { useEffect, useState, createContext } from 'react';

const MediaContext = createContext({});

const withMedia = (Component) => (props) => {
  const [mobileQ, setMobile] = useState(false);
  const [tabletQ, setTablet] = useState(false);

  useEffect(() => {
    const mobileQuery = window?.matchMedia('(max-width: 480px)');
    const tabletQuery = window?.matchMedia('(max-width: 1040px)');
    const mobileHandler = (e) => setMobile(e.matches);
    const tabletHandler = (e) => setTablet(e.matches);

    setMobile(mobileQuery.matches);
    setTablet(tabletQuery.matches);

    mobileQuery.addEventListener('change', mobileHandler);
    tabletQuery.addEventListener('change', tabletHandler);

    return () => {
      mobileQuery.removeEventListener('change', mobileHandler);
      tabletQuery.removeEventListener('change', tabletHandler);
    };
  }, []);

  return (
    <MediaContext.Provider value={{
      mobile: mobileQ,
      tablet: tabletQ,
      desktop: !mobileQ && !tabletQ,
    }}
    >
      <Component {...props} />
    </MediaContext.Provider>
  );
};

export { withMedia, MediaContext };
