import cx from 'classnames';
import { useContext, useEffect } from 'react';
import Link from 'next/link';
import { useToasts } from 'react-toast-notifications';
import { useLazyQuery } from '@apollo/client';
import UserContext from 'utils/context';
import { useCallDataIfLoggedIn } from 'utils/customHooks';
import { PROMO_ITEM } from 'utils/localStorageKey';
import { setToLocal, getRemoveFromLocal } from 'utils/storage';

import {
  balanceConfig,
} from 'utils/menu';

import { GET_USER_PROMOTIONS } from 'lib/api';

import styles from './balanceLink.module.css';

const BalanceLink = ({ route }) => {
  const { addToast, removeAllToasts } = useToasts();
  const { user } = useContext(UserContext);
  const [getPromotions, { data = {} }] = useLazyQuery(GET_USER_PROMOTIONS, {
    pollInterval: 60000,
  });

  useCallDataIfLoggedIn(getPromotions);

  const dismiss = (code) => setToLocal(`${PROMO_ITEM}-${user.nickname}-${code}`, true);
  const hasPromotion = data?.promotions?.length > 0;

  useEffect(() => {
    (data?.promotions || []).forEach(({ code }) => {
      const reviewed = getRemoveFromLocal(`${PROMO_ITEM}-${user.nickname}-${code}`);
      if (!reviewed) {
        addToast((
          <p>
            You have new promotion. To see more details and claim promo, go to
            <Link href="/balance">
              <a
                onClick={() => {
                  dismiss(code);
                  removeAllToasts();
                }}
                className="link ml-1"
              >
                Balance page
              </a>
            </Link>
            .
          </p>
        ), {
          appearance: 'info',
          onDismiss: () => dismiss(code),
        });
      }
    });
  }, [data?.promotions, removeAllToasts]);

  return (
    <Link href={balanceConfig.link}>
      <a className={cx('flex text-white underline px-2 py-1 mr-4', styles.block, {
        [styles.menuActive]: balanceConfig.link === route,
      })}
      >
        { hasPromotion && <span className={styles.topUp}>Top up</span>}
        <span>
          {`${balanceConfig.name}:`}
          {' '}
          <span className="whitespace-no-wrap">
            {`${user.accountInfo.balance.units / 100}`}
            &#x1F4B0;
          </span>
        </span>
      </a>
    </Link>
  );
};

export default BalanceLink;
