import 'normalize.css/normalize.css';
import './_global.css';
import 'swiper/swiper-bundle.min.css';
import 'components/carousel/carousel.css';
import 'tippy.js/dist/tippy.css';

import { useRouter } from 'next/router';
import Link from 'next/link';

import { ToastProvider } from 'react-toast-notifications';
import { useState, useEffect, useCallback } from 'react';

import Layout from 'components/layout';
import Snackbar from 'components/snackbar';
import UserProvider from 'components/user/userProvider';
import ToastNotifications from 'components/notifications/toastMess';
import ErrorBoundary from 'components/errorBoundary';

import apolloClient from 'lib/apollo';
import { ApolloProvider } from '@apollo/client';

import { setToLocal, getRemoveFromLocal } from 'utils/storage';
import { SAVED_COOKIE } from 'utils/localStorageKey';

/* eslint-disable react/jsx-props-no-spreading */
const App = ({ Component, pageProps }) => {
  const router = useRouter();
  const [openCookie, setOpenCookie] = useState(false);
  const closeHandler = useCallback(() => {
    setOpenCookie(false);
    setToLocal(SAVED_COOKIE, false);
  }, []);

  useEffect(() => {
    const state = getRemoveFromLocal(SAVED_COOKIE);
    setOpenCookie(state !== null ? state : true);
  }, []);

  useEffect(() => {
    const handler = () => {
      window.scrollTo(0, 0);
    };
    router.events.on('routeChangeComplete', handler);

    return () => {
      router.events.off('routerChangeComplete', handler);
    };
  });

  return (
    <ApolloProvider client={apolloClient}>
      <ToastProvider>
        <UserProvider>
          <Layout>
            <ErrorBoundary>
              <Component {...pageProps} />
            </ErrorBoundary>
            <ToastNotifications />
            <Snackbar
              open={openCookie}
              onClose={closeHandler}
            >
              PoolUp uses cookies. You can find out more
              {' '}
              <Link href="/cookies">
                <a onClick={closeHandler} target="_blank" className="underline">here</a>
              </Link>
              .
            </Snackbar>
          </Layout>
        </UserProvider>
      </ToastProvider>
    </ApolloProvider>
  );
};
/* eslint-enable react/jsx-props-no-spreading */

App.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return { pageProps };
};

export default App;
