var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"XNDtUc6oU8tCOV6jUtEpt"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://71f5ced8854a4dadae7ac5bc2c89b5c1@o4505608402436096.ingest.sentry.io/4505608412069888',
  integrations: [
    new Sentry.BrowserTracing(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  enabled: process.env.NODE_ENV === 'production',
});
