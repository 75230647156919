import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_DATA } from 'lib/api';
import UserContext from 'utils/context';
import useSseUpdate from 'utils/sse';

import { useToastOnEffect, useSetCssProperty } from 'utils/customHooks';
import { getRemoveFromLocal } from 'utils/storage';

import { BASKET_BETS, BASKET_PRIVATE } from 'utils/localStorageKey';

const UserProvider = ({ children }) => {
  const [getUserData, { loading, error, data = {} }] = useLazyQuery(GET_USER_DATA);

  const { updatedSse, getEvtSourceConOrOpen } = useSseUpdate(data?.user);

  const [basket, setBasket] = useState({
    bets: [],
    private: [],
  });

  useSetCssProperty([true], '--n-padding-b', '68px');
  useToastOnEffect('Login failed 😔. Please try again.', [error], 'error');

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    const nickname = data?.user?.nickname;
    if (nickname) {
      setBasket({
        bets: getRemoveFromLocal(`${BASKET_BETS}-${nickname}`) || [],
        private: getRemoveFromLocal(`${BASKET_PRIVATE}-${nickname}`) || [],
      });
    }
  }, [data?.user]);

  return (
    <UserContext.Provider
      value={{
        user: data.user,
        loading,
        error,
        basket,
        getEvtSourceConOrOpen,
        updatedSse,
        updateUser: getUserData,
        updateBasket: setBasket,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
