import {
  useEffect, useContext, useState, useCallback,
} from 'react';
import { useToasts } from 'react-toast-notifications';
import UserContext from 'utils/context';

const useClickOutside = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

const useToastOnEffect = (message, dep = [], type = 'info', dismiss = true, dismissFunc = () => {}) => {
  const { addToast } = useToasts();

  useEffect(() => {
    const trigger = dep.filter(Boolean);
    const online = window?.navigator?.onLine;
    if (dep.length === 0 || trigger.length > 0) {
      if (type === 'error') {
        dep.forEach((it) => {
          console.error(it);
        });
      }
      addToast(online ? message : 'You are offline. Check your internet connection.', {
        appearance: type,
        autoDismiss: dismiss,
        onDismiss: dismissFunc,
      });
    }
  }, dep);
};

const useCallDataIfLoggedIn = (...callBacks) => {
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user?.nickname) {
      callBacks.forEach((it) => it());
    }
  }, [user]);
};

const bodyCssProps = '--body-overflow';

const useSetBodyOverflow = (dep = []) => {
  useEffect(() => {
    const root = document.documentElement;

    if (dep.filter(Boolean).length > 0) {
      root?.style?.setProperty(bodyCssProps, 'hidden');
    } else {
      root?.style?.removeProperty(bodyCssProps);
    }

    return () => root?.style?.removeProperty(bodyCssProps);
  }, dep);
};

const useSetCssProperty = (dep = [], prop, val) => {
  useEffect(() => {
    const root = document.documentElement;

    if (dep.filter(Boolean).length > 0) {
      root?.style?.setProperty(prop, val);
    } else {
      root?.style?.removeProperty(prop);
    }

    return () => root?.style?.removeProperty(prop);
  }, dep);
};

const useCopyToClipboard = () => {
  const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
    el.select();
    const success = document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
    return success;
  };

  const [copied, setCopied] = useState(false);

  const copy = useCallback((text) => {
    setCopied(copyToClipboard(text));
    setTimeout(() => setCopied(false), 700);
  }, []);
  useEffect(() => () => setCopied(false), []);

  return [copied, copy];
};

export {
  useClickOutside,
  useSetBodyOverflow,
  useSetCssProperty,
  useToastOnEffect,
  useCallDataIfLoggedIn,
  useCopyToClipboard,
};
