import {
  ApolloClient, InMemoryCache, ApolloLink, HttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import { LOGIN_REFRESH } from 'lib/api';
import {
  GRAPHQL_PATH, getToken, setToken, clearToken, ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY,
} from 'utils/api';

const innerClient = new ApolloClient({
  uri: GRAPHQL_PATH(),
  cache: new InMemoryCache(),
});

const authLink = setContext(async (_, { headers }) => {
  const newHeaders = { ...headers };

  const accessToken = getToken(ACCESS_TOKEN_KEY);
  const refreshToken = getToken(REFRESH_TOKEN_KEY);
  if (accessToken) {
    // token is present, add to header
    newHeaders.authorization = `Bearer ${accessToken}`;
  } else if (refreshToken) {
    // exchange refresh token
    try {
      const resp = await innerClient.mutate({
        mutation: LOGIN_REFRESH,
        variables: {
          token: refreshToken,
        },
      });
      const tokens = resp.data.refresh;
      setToken(ACCESS_TOKEN_KEY, tokens.access.value, tokens.access.expires);
      setToken(REFRESH_TOKEN_KEY, tokens.refresh.value, tokens.refresh.expires);

      newHeaders.authorization = `Bearer ${tokens.access.value}`;
    } catch (ex) {
      console.error('failed to exchange refresh token:', ex);
      clearToken(REFRESH_TOKEN_KEY);
    }
  }

  return {
    headers: newHeaders,
  };
});

const httpLink = new HttpLink({ uri: GRAPHQL_PATH() });

const apolloClient = new ApolloClient({
  uri: GRAPHQL_PATH(),
  credentials: 'include',
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    mutate: {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
    },
  },
  link: ApolloLink.from([authLink, httpLink]),
});

export default apolloClient;
