import cx from 'classnames';
import { useContext } from 'react';
import { MediaContext } from 'components/mediaQuery';
import Link from 'next/link';

import styles from './footer.module.css';

const dataNow = new Date();

const Footer = () => {
  const { mobile } = useContext(MediaContext);

  return (
    <footer className={cx(styles.footer, 'py-8')}>
      <div className={cx(styles.inner, 'px-6')}>
        <div className={styles.logoBlock}>
          <Link href="/">
            <a className={cx(styles.logo, 'hover:underline')}>
              Poolup
            </a>
          </Link>
          <a className={styles.trustpilot} href="https://uk.trustpilot.com/review/poolup.club" target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
        <div className={cx(styles.linkBlock)}>
          <Link href="/terms">
            <a className={styles.link}>
              Terms & Conditions
            </a>
          </Link>
          <Link href="/faq">
            <a className={styles.link}>
              FAQ
            </a>
          </Link>
          <Link href="/cookies">
            <a className={styles.link}>
              Cookies
            </a>
          </Link>
          <Link href="/contact">
            <a className={styles.link}>
              Contact us
            </a>
          </Link>
          {!mobile && (
            <div className={styles.copyBox}>
              <img className="mr-2" src="/18+.png" width={32} height={32} alt="18+" />
              <span className={cx(styles.copy, 'text-center')}>
                © Copyright Buzovska Chernysh {dataNow.getFullYear()}. All Rights Reserved.
              </span>
              <a className={cx(styles.copyLink, styles.copyLinkFirst)} href="https://www.gamcare.org.uk" target="_blank" rel="noreferrer">
                <img src="/gameStop/gamcare.png" width={100} height={29} alt="gamcare" />
              </a>
              <a className={styles.copyLink} href="https://www.ncpgambling.org" target="_blank" rel="noreferrer">
                <img src="/gameStop/ncpgambling.png" width={95} height={29} alt="ncpgambling" />
              </a>
              <a className={cx(styles.copyLink, styles.copyLinkLast)} href="https://www.begambleaware.org" target="_blank" rel="noreferrer">
                <img src="/gameStop/begambleaware.png" width={126} height={17} alt="begambleaware" />
              </a>
            </div>
          )}
        </div>
        {mobile && (
          <>
          <div className={styles.copyBox}>
            <img className="mr-2" src="/18+.png" width={32} height={32} alt="18+" />
            <span className={cx(styles.copy, 'text-center')}>
              © Copyright Buzovska Chernysh {dataNow.getFullYear()}. All Rights Reserved.
            </span>
          </div>
          <div className="flex-center mt-2 mb-4">
            <a className={cx(styles.copyLink, styles.copyLinkFirst)} href="https://www.gamcare.org.uk" target="_blank" rel="noreferrer">
              <img src="/gameStop/gamcare.png" width={100} height={29} alt="gamcare" />
            </a>
            <a className={styles.copyLink} href="https://www.ncpgambling.org" target="_blank" rel="noreferrer">
              <img src="/gameStop/ncpgambling.png" width={95} height={29} alt="ncpgambling" />
            </a>
            <a className={cx(styles.copyLink, styles.copyLinkLast)} href="https://www.begambleaware.org" target="_blank" rel="noreferrer">
              <img src="/gameStop/begambleaware.png" width={126} height={17} alt="begambleaware" />
            </a>
          </div>
          </>
        )}
      </div>
    </footer>
  );
};

export default Footer;
