const setToLocal = (key, val) => {
  try {
    window.localStorage.setItem(key, val);
  } catch (error) {
    console.error(error);
  }
};

const setLocal = (key, val) => {
  try {
    window.localStorage.setItem(key, val);
    return true;
  } catch (ex) {
    console.error(`failed to add '${key}' to local storage:`, ex);
    return false;
  }
};

const getLocal = (key) => {
  try {
    return window.localStorage.getItem(key);
  } catch (ex) {
    console.error(`failed to get '${key}' from local storage:`, ex);
    return null;
  }
};

const clearLocal = (key) => {
  try {
    window.localStorage.removeItem(key);
    return true;
  } catch (ex) {
    console.error(`failed to remove '${key}' from local storage:`, ex);
    return false;
  }
};

const getRemoveFromLocal = (key, remove = false) => {
  const method = remove ? 'removeItem' : 'getItem';

  try {
    const state = window.localStorage[method](key);

    if (state) {
      return JSON.parse(state);
    }

    return null;
  } catch (error) {
    return false;
  }
};

const setToSession = (key, val) => {
  try {
    window.sessionStorage.setItem(key, val);
  } catch (error) {
    console.error(error);
  }
};

const getRemoveFromSession = (key, remove = false) => {
  const method = remove ? 'removeItem' : 'getItem';

  try {
    const state = window.sessionStorage[method](key);

    if (state) {
      return JSON.parse(state);
    }

    return null;
  } catch (error) {
    return false;
  }
};

export {
  setToLocal, getRemoveFromLocal, setToSession, getRemoveFromSession, setLocal, getLocal, clearLocal,
};
